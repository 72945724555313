import { observer } from "mobx-react";

import ScheduleSelector from "react-schedule-selector";
import { useStore } from "../../../../store";

import styles from "./schedule.module.css";
import { useState } from "react";

function CourseSchedule() {
  const { courseStore } = useStore();

  let course: any = courseStore.courseDetails;

  const startTime = new Date(course.startTime);
  const endTime = new Date(course.endTime);
  //   const startTime = new Date("2024-09-03T09:00:00.000Z");
  //   const endTime = new Date("2024-09-05T13:00:00.000Z");

  const schedule = [];
  let currentDate = new Date(startTime);

  while (currentDate <= endTime) {
    for (let hour = 9; hour <= 13; hour++) {
      const currentTime = new Date(currentDate);
      currentTime.setUTCHours(hour, 0, 0, 0);
      schedule.push(new Date(currentTime));
    }
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }

  return (
    <div className={styles.course_schedule} id="schedule">
      <div className={styles.course_schedule_content}>
        <h3 className={styles.course_schedule_title}>Timetable</h3>
        <ScheduleSelector
          selection={schedule}
          minTime={8}
          maxTime={18}
          dateFormat="ddd M/D"
          timeFormat="h:mma"
          selectedColor="#CA2D5D"
        />
      </div>
    </div>
  );
}

export default observer(CourseSchedule);
