import { observer } from "mobx-react";

import { useStore } from "../../../../store";
import { Button } from "../../../../components";
import styles from "./about.module.css";

function AboutCourse() {
  const { courseStore } = useStore();

  const course: any = courseStore.courseDetails;

  return (
    <div className={styles.about_course} id="about-course">
      <div className={styles.about_course_content}>
        <h3 className={styles.about_course_title}>About course</h3>

        <div className="w-full flex flex-col md:flex-row justify-between items-start">
          <div className={styles.about_course_details}>
            <h4>{course?.title}</h4>
            <h5>Details:</h5>
            <p>{course?.detailedDescription}</p>
          </div>

          <div className={styles.about_course_list}>
            <div className={styles.about_course_list_item}>
              <div className={styles.about_course_list_item_icon}>
                <img
                  width={24}
                  height={24}
                  alt="list icon"
                  src="/images/icons/authentic-certificate.svg"
                />
              </div>
              <div className={styles.about_course_list_item_text}>
                <h6>Authentic Certificate</h6>
                <span>Earn a Certificate upon completion.</span>
              </div>
            </div>
            <div className={styles.about_course_list_item}>
              <div className={styles.about_course_list_item_icon}>
                <img
                  width={24}
                  height={24}
                  alt="list icon"
                  src="/images/icons/onsite-training.svg"
                />
              </div>
              <div className={styles.about_course_list_item_text}>
                <h6>Onsite training</h6>
                <span>Face to face training.</span>
              </div>
            </div>
            <div className={styles.about_course_list_item}>
              <div className={styles.about_course_list_item_icon}>
                <img
                  width={24}
                  height={24}
                  alt="list icon"
                  src="/images/icons/safe-spaces.svg"
                />
              </div>
              <div className={styles.about_course_list_item_text}>
                <h6>Safe spaces</h6>
                <span>Safe space for growth and learning.</span>
              </div>
            </div>
            <div className={styles.about_course_list_item}>
              <div className={styles.about_course_list_item_icon}>
                <img
                  width={24}
                  height={24}
                  alt="list icon"
                  src="/images/icons/beginner-level.svg"
                />
              </div>
              <div className={styles.about_course_list_item_text}>
                <h6>Beginner Level</h6>
                <span>No prior experience required.</span>
              </div>
            </div>

            <div>
              <span className={styles.btn_text}>
                Want to get full details about this course?
              </span>
              <Button
                type="secondary"
                text="Download Syllabus"
                onClick={() => {}}
                icon="/images/icons/download.svg"
                customStyle={{ marginTop: "0.5em" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(AboutCourse);
