import axiosInstance from "../config/api";

export interface Session {
  id?: string;
  title: string;
  price: number;
  start: Date;
  end: Date;
  SessionImage: object;
  status: string;
  completeness?: string;
  startTime?: Date;
  endTime?: Date;
  duration: number;
  description?: string;
  prerequisite?: any[];
  subscribedNumber: number;
  subscribedPercent: number;
  nbrStillAvailable: number;
  minPlace: number;
  maxPlace: number;
  TimeWindowForCancel: number;
  deleted: boolean;
  discount: number;
  difficulty?: string;
  rating: number;
  ratingsCount: number;
  domains?: string[];
  maxParticipants: number;
  isOnline: boolean;
  onlineSessionLink?: string;
  everyDay: number;
  scheduleData: object;
  category?: string;
  contributorsId?: string[];
  level?: string;
  studentsId?: string[];
  sessionsId?: string[];
  courseId?: string;
  coursePhoto?: object;
  syllabus?: object;
  sessionBooking?: any[];
  tutors?: any;
}

export async function getAllSessionsAndCourses(): Promise<any | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(
      `/Sessions/list?access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getMySessions(
  id: string,
  limit: number,
  skip: number,
  tab: number
): Promise<any | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(
      `/Sessions/mySessions/${id}?limit=${limit}&skip=${skip}&tab=${tab}&id=${id}&access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getListSessions(
  id: string,
  limit: number,
  skip: number,
  tab: number
): Promise<any | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(
      `/Sessions/getListSessions/${id}?limit=${limit}&skip=${skip}&tab=${tab}&id=${id}&access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function newSession(session: object): Promise<any | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      `/Sessions/newSession?access_token=${token}`,
      [session]
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getSessionDetails(id: string): Promise<any> {
  const token = localStorage.getItem("token");
  try {
    const session = await axiosInstance.get(
      `/Sessions/details/${id}?access_token=${token}`
    );
    const teacher = await getTeacherDetails(session.data.tutors.id);
    return { ...session.data, teacher };
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getTeacherDetails(id: string) {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(
      `/Profiles/instructorDetails/${id}?access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function rateSession(body: object) {
  const token = localStorage.getItem("token");
  await axiosInstance.post(`/Profiles/giveNote?access_token=${token}`, body);
}
