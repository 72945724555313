import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import styles from "./header.module.css";
import { useStore } from "../../../../store";

interface IProps {
  type: "course" | "cohort";
}

function CourseBreadcrumbs({ type }: IProps) {
  const { courseStore } = useStore();


  const course: any = courseStore.courseDetails;
    
  const links = type === "cohort" ? [
    { path: "/", label: "Home" },
    { path: "/courses", label: "Courses" },
    { path: `/course/${course?.sessionCourse?.id}`, label: course?.sessionCourse?.title },
    { path: "/courses", label: "Cohorts" },
  ] : [
    { path: "/", label: "Home" },
    { path: "/courses", label: "Courses" },
  ];

  return (
    <div className={styles.course_breadcrumbs}>
      <div className={styles.course_breadcrumbs_links}>
        {links.map((link, index) => (
            <div className={styles.link} key={index}>
                <Link to={link.path} className={styles.label}>
                    {link.label}
                </Link>
                <div className={styles.arrow}>&gt;</div>
            </div>
        ))}
        <div className={styles.active}>
            {course.title}
        </div>
    </div>
    </div>
  );
}

export default observer(CourseBreadcrumbs);
