import { observer } from "mobx-react";
import styles from "./stories.module.css";
import { useStore } from "../../../../store";
import env from "../../../../config/env";

function CourseStories({ type }: { type: "course" | "cohort" }) {
    const { courseStore } = useStore();

    let course : any = courseStore.courseDetails;
    if (type === "cohort") {
        course = course?.sessionCourse;
    }
    const stories: any = course?.testimonials || [];

    if (stories.length === 0 || stories[0].author?.length === 0) return null;

    return (
        <div className={styles.course_stories} id="success-stories">
            <div className={styles.course_stories_content}>
                <div className={styles.course_stories_info}>
                    <h4 className={styles.course_stories_subtitle}>Success Stories</h4>
                    <h3 className={styles.course_stories_title}>Excellent course !</h3>
                    <p className={styles.course_stories_description}>
                        {stories[0].content}
                    </p>
                </div>

                <div className={styles.course_stories_author}>
                    <img
                        width={80}
                        height={80}
                        alt="Reviewer 1"
                        src={stories[0].photo?.container?.name ? `${env.IMAGE_API}${stories[0].photo.container.name}` : "/images/we-code.jpeg"}
                        className={styles.course_stories_author_image}
                    />
                    <div>
                        <h4 className={styles.course_stories_author_name}>{stories[0].author}</h4>
                        {/* <p className={styles.course_stories_author_position}>Web Developer at Wecode</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(CourseStories);