import styles from "./instructor.module.css";
import { useStore } from "../../../../store";
import { observer } from "mobx-react";
import env from "../../../../config/env";

interface IProps {
    type: "course" | "cohort";
}

function AboutInstructor({ type }: IProps) {
    const { courseStore } = useStore();

    const course : any = courseStore.courseDetails;
    const instructor : any = courseStore.instructorDetails;
    
    return (
        <div className={styles.course_instructor} id="about-instructor">
            <div className={styles.course_instructor_content}>
                <h3 className={styles.course_instructor_title}>About {type === "cohort" ? "instructor" : "creator"}</h3>

                <div className={styles.course_instructor_info}>
                    <div>
                        <img
                            className={styles.course_instructor_image}
                            width={224}
                            height={224}
                            alt="avatar"
                            src={instructor?.image?.name ? env.IMAGE_API + instructor.image.name : "/images/default-user.svg"}
                        />
                        <div className={styles.instructor_infos}>
                            <div className={styles.instructor_info}>
                                <img src="/images/icons/star-pink.svg" alt="" />
                                <span className={styles.instructor_info_number}>
                                    <span>
                                        {instructor?.rating}
                                    </span>
                                    Instructor Rating
                                </span>
                            </div>
                            <div className={styles.instructor_info}>
                                <img src="/images/icons/review.svg" alt="" />
                                <span className={styles.instructor_info_number}>
                                    <span>
                                        {instructor?.ratingsCount}
                                    </span>
                                    Reviews
                                </span>
                            </div>
                            <div className={styles.instructor_info}>
                                <img src="/images/icons/student-pink.svg" alt="" />
                                <span className={styles.instructor_info_number}>
                                    <span>
                                        {instructor?.ratingsCount}
                                    </span>
                                    Students
                                </span>
                            </div>
                            <div className={styles.instructor_info}>
                                <img src="/images/icons/document-pink.svg" alt="" />
                                <span className={styles.instructor_info_number}>
                                    <span>
                                        {instructor?._courses}
                                    </span>
                                    Courses
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.course_instructor_details}>
                        <h4>{instructor?.fullName ? instructor?.fullName : course?.tutors?.fullName}</h4>
                        {instructor?.profession ? (
                            <h5>{instructor?.profession}</h5>
                        ) : null}
                        <hr />
                        {instructor?.description ? (
                            <p>{instructor.description}</p>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AboutInstructor);
